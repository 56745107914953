@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.ant-input {
  border-width: 1px;
  border: 2px 2px 2px 2px solid grey;
  border-color: grey;
  color: grey;
  min-height: 40px;
  padding-left: 30px;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  padding-left: 30px;
}

.ant-input:hover {
  border-width: 1px;
  border: 1px 1px 1px 1px solid #17a7e5;
  border-color: #17a7e5;
  color: #17a7e5;
  box-shadow: 0px 0px 0px 1px #17a7e5;
  padding-left: 30px;
}
.ant-input.correcto {
  border-width: 1.5px;
  border: 2px 2px 2px 2px solid #389b73;
  border-color: #389b73;
  color: #389b73;
  padding-left: 30px;
}
.ant-input.correcto:hover {
  border-width: 1.5px;
  border: 2px 2px 2px 2px solid #389b73;
  border-color: #389b73;
  color: #389b73;
  box-shadow: 0px 0px 0px 1px #389b73;
  padding-left: 30px;
}
.ant-input.incorrecto {
  border-width: 1.5px;
  border: 1px 1px 1px 1px solid #79134c;
  border-color: #79134c;
  color: #79134c;
  box-shadow: 0px 0px 1px 1px #79134c;
  padding-left: 30px;
}
.ant-input.incorrecto:hover {
  border-width: 1.5px;
  border: 1px 1px 1px 1px solid #79134c;
  border-color: #79134c;
  color: #79134c;
  box-shadow: 0px 0px 0px 1px #79134c;
  padding-left: 30px;
}

.ant-tooltip-inner {
  color: #ffffff;
  background-color: #333;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #333;
}
.ant-tooltip-arrow::before {
  background-color: #333;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  padding-left: 30px;
  border: 1px solid #333333;
  -webkit-text-fill-color: #333333;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
