html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.footer_login {
  position: relative;
  margin-top: -150px; /* negative value of footer height */
  height: 150px;
  clear: both;
  padding-top: 20px;
}

.login-block {
  background: white; /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to bottom, #FFB88C, #DE6262);  /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(to bottom, #FFB88C, #DE6262); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 3.5% 0;
  position: relative;
  margin: 0;
}
.banner-sec {
  background: no-repeat left bottom;
  padding-right: 0px;
  padding-left: 0px;
  background-size: cover;
  min-height: 500px;
  border-radius: 0 0px 0px 0;
  padding: 0;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: white;
}
.container, .container-lg, .container-md, .container-sm {
  max-width: 1200px;
}
.containerApps, .containerApps-lg, .containerApps-md, .containerApps-sm {
  max-width: 800px;
}
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container {
  align-content: center;
  vertical-align: middle;
  background: white;
  border-radius: 1px;
  box-shadow: 0px 6px 6px 6px rgba(0, 0, 0, 0.1);
  width: "25%";
}
.container2 {
  background: white;
  border-radius: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 0px 0px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: white;
}
.login-sec .copy-text a {
  color: white;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: white;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: white;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: white;
  color: #fff;
  font-weight: 600;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}
.parent {
  position: relative;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 10px;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #d5007f;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-upload-list-picture-card-container {
  float: left;
  @media screen and (min-width: 137px) and (max-width: 375px) {
    width: 130px;
    height: 130px;
  }
  @media screen and (min-width: 376px) and (max-width: 414px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (min-width: 767px) {
    width: 150px;
    height: 150px;
  }

  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  margin: 0 8px 8px 0;
  @media screen and (min-width: 137px) and (max-width: 375px) {
    width: 130px;
    height: 130px;
  }
  @media screen and (min-width: 376px) and (max-width: 414px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (min-width: 767px) {
    width: 150px;
    height: 150px;
  }
}
.ant-upload.ant-upload-select-picture-card:hover {
  /* border: 1px solid #d5007f; */
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  @media screen and (min-width: 137px) and (max-width: 375px) {
    width: 130px;
    height: 130px;
  }
  @media screen and (min-width: 376px) and (max-width: 414px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (min-width: 767px) {
    width: 150px;
    height: 150px;
  }
}
.ant-upload-select-picture-card i {
  font-size: 28px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  font-size: 12px;
  color: lightgray;
}

/* plus +  icon*/
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #333333;
}
/* Upload text*/
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #333333;
  font-size: 14px;
}

/* Uploading... text*/
.ant-upload-list-item-info
  > span
  > div[class="ant-upload-list-item-uploading-text"] {
  visibility: hidden;
}

/* you need to customise top and left css attribute */
.ant-upload-list-item-info
  > span
  > div[class="ant-upload-list-item-uploading-text"]:after {
  content: "Cargando...";
  font-size: 16px;
  visibility: visible;
  display: block;
  position: absolute;
  padding: 1px;
  top: 10px;
  left: 10px;
}

/* div {
  border: 1px solid red;
}

div.row,
div.container {
  border: 0;
} */

.h11,
h11 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.h33,
h33 {
  font-size: 18px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.h45,
h45 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.h55,
h55 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 3;
  color: #333333;
  font-family: "Roboto", sans-serif;
}
.h44,
h4 {
  font-size: 16px;
}
.h55,
h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.h66,
h66 {
  font-size: 12px;
  padding-left: 16px;
  font-family: "Roboto", sans-serif;
}
.firstRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 35px;
}
.secondRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 15px;
}
.h45-version,
h45-version {
  font-size: 9px;
  font-family: "Roboto", sans-serif;
  color: #333333;
}