.ant-layout-header {
  /*Se sobreescribe las propiedades del componente header a tráves del className ant-layout-header*/
  background: #333333 !important;
  height: 50px !important;
  line-height: 50px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  /*Se configura las img y textos que se encuentran en el header*/
  .menu-top {
    display: flex;
    &__logo {
      svg {
        height: 30px;
      }
    }
    &__ayuda {
      text-align: right;
      width: 100%;
      p {
        color: rgb(255, 255, 255);
      }
    }
  }
  .user {
    width: 24px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .logo {
    vertical-align: middle;
    border-style: none;
    width: 125px;
    top: 0px;
  }
  a {
    color: white;
  }
}


.ant-btn-link {
  color: white !important;
  background-color: transparent!important;
  border-color: transparent!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  // padding: 0;
}
.ant-btn-link:active {
  color: white !important;
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  // padding: 0 !important;
}
.ant-btn-link:focus {
  color: white !important;
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  // padding: 0 !important;
}
.ant-btn-link:hover {
  color: #d5007f !important;
  //background-color: #d5007f !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  // padding: 0 !important;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 50px;
}