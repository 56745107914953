.ant-layout-footer {
  /*Se sobreescribe las propiedades del componente header a tráves del className ant-layout-header*/
  background: #333333 !important;
  height: 60px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 12px;
  position: inherit;
  bottom: 0;
  .footer {
    position: relative;
    margin-top: -150px; /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 20px;
    align-items: center !important;
    background-color: #333333 !important;
    position: inherit !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 60px !important;
    font-size: 12px !important;
    font-family: "Roboto", sans-serif !important;

    color: white !important;

    &__instituto {
      @media screen and (max-width: 795px) {
        font-size: 0px;
      }
      text-align: left;
      width: 100%;
      svg {
        height: 30px;
      }
      span {
        padding-left: 20px;
      }
      //960px
      @media screen and (max-width: 960px) {
        a {
          visibility: hidden;
          font-size: 0px;
        }
      }
    }
    &.area {
      text-align: center;
      width: 100%;
      font-family: Roboto;
    }
    &.version {
      text-align: right;
      width: 100%;
      font-family: Roboto;
    }

    &a:hover {
      text-decoration: underline;
      color: black;
      //margin: 20px 0px 0 20px;
      padding-left: 0px;
    }
  }
}
.footer {
  display: flex !important;
  align-items: center !important;
  background-color: #333333 !important;
  position: inherit;
  bottom: 0;
  width: 100% !important;
  height: 50px !important;
  font-size: 12px !important;

  font-family: "Roboto", sans-serif !important;
  color: white !important;
  &__instituto {
    text-align: left;
    width: 100%;
    svg {
      height: 30px;
    }
    span {
      padding-left: 20px;
    }
  }
  &__area {
    text-align: center;
    width: 100%;
  }
  &__version {
    text-align: right;
    color: white;
    width: 100%;
  }
  a {
    font-size: 12px;
    text-decoration: underline;
    color: white;
    margin: 0px 0px 0px 20px;
    padding-left: 0px;
  }
  a:hover {
    font-size: 12px;
    text-decoration: underline;
    color: #d5007f;
    margin: 0px 0px 0px 20px;
    padding-left: 0px;
  }
}
#over img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
