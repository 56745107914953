@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form-section .form-group label {
  font-size: 40px;
}

.myTestClass > * {
  color: red;
  margin: 0 20px;
}
.carousel .control-dots .dot:hover {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: white;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.dot:focus {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #d5007f;
  border-color: #d5007f;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
div.polaroid {
  text-align: center;
  border: none;
  max-height: 100%;
  border-radius: 10px;
}
div.polaroid:hover {
  transition: background-color 1s ease;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
  -webkit-box-shadow: 3px 8px 17px -6px black;
  -moz-box-shadow: 3px 8px 17px -6px black;
  box-shadow: 3px 8px 17px -6px black;
  border-radius: 10px;
}
.ant-notification-notice {
  align-self: center;
  border-radius: 0;
  font-size: 16px;
  font-family: "Roboto", Roboto;
}
.ant-btn-primary {
  background-color: #db2491;
  border-color: #db2491;
  font-size: 16px;
}
.ant-btn-primary:hover {
  background-color: white;
  border-color: #db2491;
  color: #db2491;
}
.ant-btn-primary:active {
  background-color: white;
  border-color: #db2491;
  color: #db2491;
}
.ant-btn-primary:focus {
  background-color: white;
  border-color: #db2491;
  color: #db2491;
}
.ant-btn-secondary {
  background-color: white;
  border-color: #db2491;
  color: black;
}
.ant-btn-secondary:hover {
  background-color: #e149a4;
  border-color: #e149a4;
  color: white;
}
.ant-btn-secondary:focus {
  background-color: white;
  border-color: #db2491;
  color: black;
}
.ant-btn-secondary:active {
  background-color: #d5007f;
  border-color: #d5007f;
  color: white;
}
.ant-input-affix-wrapper{
  background: transparent;
}


p.normal {
  background-position: 150px 8px;
  color: red;
}
p.active {
  background-position: 4px 8px;
  color: green;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #333333;
  -webkit-text-fill-color: #333333;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
.labelsINE {
  font-size: 16px;
  font-family: Roboto;
  color: black;
}
