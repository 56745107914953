@primary-color: #1890ff; // primary color for all components
.div_login {
  /*Centrar el formulario, igual se puede configurar de acuerdo a las necesidades*/
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0;
  padding-bottom: 50px;
  line-height: 1.15;
  margin-top: 35px;
  border: none;
  width: 380px;
  height: 485px;
  .login-form {
    max-width: 280px;
    padding-top: 65px;
    min-width: 280px;
    &__button {
      /*propiedades del boton*/
      height: 40px;
      width: 280px;
      background-color: #d5007f;
      color: #ffffff;
      font-size: 16px;
      border-radius: 8px;
    }
    ::placeholder {
      color: #333333 !important;
      font-size: 16px;
    }
    /*Tamaño del capcha*/
    &__capcha,
    .rc-imageselect {
      transform: scale(0.5);
      transform-origin: 0 0;
      // padding-left: 15%;
    }
  }
  a {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    color: #d5007f;
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      color: #e767b4;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  .login-image {
    height: 100px;
    width: 300px;
  }
}

.captcha {
  &__capcha,
  .rc-imageselect {
    padding-left: 14px;
    
    @media screen and (min-width: 137px) and (max-width: 164px) {
      transform: scale(0.15);
    }
    @media screen and (min-width: 165px) and (max-width: 229px) {
      transform: scale(0.33);
      padding-left: 44px;
    }
    @media screen and (min-width: 296px) and (max-width: 767px) {
      transform: scale(0.67);
    }
    @media screen and (min-width: 276px) and (max-width: 295px) {
      transform: scale(0.6);
    }
    @media screen and (min-width: 260px) and (max-width: 276px) {
      transform: scale(0.55);
    }
    @media screen and (min-width: 244px) and (max-width: 259px) {
      transform: scale(0.5);
    }
    @media screen and (min-width: 230px) and (max-width: 243px) {
      transform: scale(0.45);
    }
    @media screen and (max-width: 137px) {
      visibility: hidden;
    }
    @media screen and (min-width: 244px) and (max-width: 499px) {
      transform: scale(0.8);
      padding-left: 20px;
      
    }
    @media screen and (min-width: 500px) and (max-width: 768px) {
      transform: scale(0.668);
      padding-left: 25px;
    }
    @media screen and (min-width: 769px) and (max-width: 1025px) {
      transform: scale(0.8);
      padding-left: 20px;
    }

    @media screen and (min-width: 1025px) {
      transform: scale(1);
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
      padding-left: 23px;
      transform: scale(0.67);
    }

    text-rendering: optimizeLegibility;
    transform-origin: 0 0;

    #gwd-reCAPTCHA_2,
    #rc-imageselect {
      -webkit-transform: scale(0.84);
      -moz-transform: scale(0.84);
      -ms-transform: scale(0.84);
      -o-transform: scale(0.84);
      transform: scale(0.84);

      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    -ms-interpolation-mode: bicubic;
    image-rendering: optimizeQuality;
    border: none;
  }
  .rc-anchor-light.rc-anchor-normal {
    border: white;
  }
  .rc-anchor {
    border-radius: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  //ININCIO PRUEBAS RECAPTCHA
  .rc-anchor-checkbox-label {
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    font-size: 16px;
  }
  .rc-anchor {
    border-radius: none;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: none;
    -moz-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.08);
  }

  .rc-anchor-normal .rc-anchor-pt {
    margin: 2px 13px 0 0;
    padding-right: 2px;
    position: absolute;
    right: 0;
    text-align: right;
    width: 276px;
    font-size: 14px;
    font-family: Roboto;
    border: none;
  }
  rc-anchor-alert,
  .rc-anchor-alert {
    bottom: 0;
    color: transparent;
    font-size: 13px;
    position: absolute;
  }
  .rc-anchor-logo-text {
    cursor: default;
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    margin-top: 5px;
    text-align: center;
    margin-right: 10px;
  }

  .rc-anchor-normal {
    background: #000 !important;
    color: #000 !important;
  }
  .rc-anchor-light {
    background: #fff !important;
    color: #fff !important;
  }
  #rc-imageselect,
  .g-recaptcha {
    display: inline; //the most important
  }

  #rc-imageselect {
    max-width: 100%;
  }

  .g-recaptcha > div > div {
    width: 100% !important;
    height: 78px;
    transform: scale(
      0.77
    ); //the code to rescale the captcha obtained in this page
    webkit-transform: scale(0.77);
    text-align: center;
    position: relative;
  }
  //FIN RECAPTCHA
}

a {
  font-family: "Roboto", sans-serif;
  color: "#D5007F";
  textdecoration: "underline";
}
.aINE:hover {
  color: #d5007f;
  background-color: #fcedf6;
  height: 1550px;
  margin: 0px 0px 0px 0px;
  box-sizing: 55px 55px 55px 55px;
  max-width: 1265px;
}

.form-section .form-group label {
  font-size: 20px;
  //YOUR CUSTOM STYLE
}

linkfooter:hover {
  color: #d5007f;
  background-color: black;
}
.options:hover {
  height: 39px;
  top: -4px;
}

.alertMessageAyuda {
  color: #79134c;
  font-size: 16px;
  text-align: center;
  font-family: Roboto;
}
.alertIconAyuda {
  position: "relative";
  top: "7.5px";
  transform: "translateY(-69.5%)";
  color: "#666";
  text-align: center;
  font-size: 16px;
}
.divFormulario {
  width: 100%;
  align-items: center;
  text-align: center;
}
.logoFormulario {
  @media screen and (min-width: 137px) and (max-width: 236px) {
    width: 120px;
  }
  @media screen and (min-width: 237px) and (max-width: 991px) {
    width: 180px;
  }
  @media screen and (min-width: 992px) {
    width: 200px;
  }
  align-items: center;
  text-align: center;
}
